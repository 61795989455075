import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu";
import { options, colorThemes } from "../../utils/typography";
import Button from "../atoms/button";
import Icon, { IconClassName } from "../atoms/icon";
import TextIconButton from "../atoms/button/texticonbutton";
import { darken } from "polished";

const { selectMenu: selectMenuTheme } = colorThemes;

export type MenuItemsType = Array<{ id: string; content: ReactNode }>;

export type SelectMenuProps = {
  icon?: IconClassName;
  initialPlaceHolder?: ReactNode;
  menuItems: Array<{ id: string; content: ReactNode }>;
  selectedItemId?: string;
  onMenuItemSelected: (id: string) => void;
  minwidth?: string;
  menuAriaLabel?: string;
} & Pick<HTMLAttributes<HTMLBaseElement>, "id">;

type StyleProps = Pick<SelectMenuProps, "minwidth">;

const StyledMenuButton = styled(MenuButton)<StyleProps>`
  border-radius: 0px;
  min-width: ${({ minwidth }) => minwidth};
`;

const StyledMenu = styled(Menu)<StyleProps>`
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  max-height: calc(100vh - 56px);
  outline: 0px;
  border: 1px solid ${selectMenuTheme.border};
  z-index: 999;
  box-sizing: border-box;
  font-family: ${options.bodyFontFamily?.join(",")};
  color: ${selectMenuTheme.secondary};
  background-color: ${selectMenuTheme.primary};
  position: relative;
  flex-direction: column;
  white-space: nowrap;
  display: flex;
  border-radius: 0px;
  box-shadow: none !important;
  min-width: ${({ minwidth }) => minwidth};
`;

const StyledMenuItem = styled(MenuItem)`
  line-height: 1.5;
  padding: 0px 1.5em;
  text-align: left;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border: 0px;
  border-radius: 0px;
  font-size: 100%;
  background: transparent;
  color: ${selectMenuTheme.secondary};
  margin: 0px;
  user-select: none;
  cursor: default;
  text-decoration: none;

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    &:hover {
      background: ${darken(0.08, selectMenuTheme.primary)};
    }
    &:active,
    &[data-active="true"] {
      background: ${darken(0.12, selectMenuTheme.primary)};
    }
  }
`;

const MenuItemContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  & > :first-child {
    justify-self: flex-start;
    align-content: center;
    margin-right: auto;
  }
  width: 100%;
`;

const IsOpenIcon = ({ isOpen }: { isOpen?: boolean }) => (
  <Icon
    icon={isOpen ? "icon-chevron-up" : "icon-chevron-down"}
    styles={{fontSize: "28px"}}
  />
);

const SelectMenu: FC<SelectMenuProps> = React.forwardRef<
  HTMLButtonElement,
  SelectMenuProps
>(
  (
    {
      menuItems,
      initialPlaceHolder,
      selectedItemId,
      onMenuItemSelected,
      minwidth,
      icon,
      menuAriaLabel,
      ...rest
    },
    ref
  ) => {
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const menu = useMenuState();
    const selected = menuItems.find((c) => c.id === selectedItemId);
    let contentLeft: React.ReactNode | undefined = icon ? (
      <Icon icon={icon} styles={{fontSize: "28px"}} />
    ) : undefined;
    let contentCenter: React.ReactNode | undefined = undefined;
    const selectionContent = selected?.content ?? initialPlaceHolder ?? " ";
    if (!contentLeft) {
      contentLeft = selectionContent;
    } else {
      contentCenter = selectionContent;
    }

    const [menuWidth, setMenuWidth] = useState(minwidth);
    useEffect(() => {
      const width = menuButtonRef.current?.offsetWidth;
      setMenuWidth(width ? `${width}px` : undefined);
    }, [menuButtonRef.current?.offsetWidth]);

    return (
      <>
        <StyledMenuButton
          {...menu}
          style={{minWidth:menuWidth}}
          ref={menuButtonRef}
          aria-label={menuAriaLabel}
          {...rest}
        >
          {(props) => (
            <TextIconButton
              ref={ref}
              {...props}
              contentLeft={contentLeft}
              contentCenter={contentCenter}
              contentRight={<IsOpenIcon isOpen={menu.visible}
              aria-label={"Select icon"} />}
            />
          )}
        </StyledMenuButton>
        <StyledMenu {...menu} style={{minWidth:menuWidth}} aria-label={menuAriaLabel}>
          {menuItems.map((c) => (
            <StyledMenuItem
              key={c.id}
              {...menu}
              onClick={() => {
                onMenuItemSelected(c.id);
                menu.hide();
              }}
              >
              {(props) => (
                <MenuItemContent>
                  <Button {...{...props, "aria-label": selectedItemId === c.id ? `Selected: ${c.content}` : c.content}} style={{width:"100%"}} >
                    <span>{c.content}</span>
                    {selectedItemId === c.id && (
                      <Icon
                        icon="icon-check"
                        styles={{ fontSize: "24px" }}
                      />
                    )}
                  </Button>
                </MenuItemContent>
              )}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </>
    );
  }
);

export default SelectMenu;
