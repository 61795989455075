import React, { FC } from "react";
import { LanguagesProps } from ".";
import { LanguageSelectionHandler, LanguagesWithBackend } from "./decorators";
import SelectMenu, { SelectMenuProps } from "../../molecules/selectMenu";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import styled from "styled-components";
import { mediaBreakpoints } from "../../../utils/typography";
import { chooseLanguages } from "../../../translations/languages";

const { md } = mediaBreakpoints;

export type LanguagesMenuProps = Omit<LanguagesProps, "languageIcons">;

const StyledSelectMenu = styled(SelectMenu)<SelectMenuProps>`
  background-color: transparent;
  border: 0px;

  width: auto;
  min-width: 10rem;

  @media only screen and (min-width: ${md}) {
    min-width: ${({ minwidth }) => minwidth};
  }
`;

export const LanguagesMenu: FC<LanguagesMenuProps> = ({
  languages,
  omitLanguageCodes = [],
  onLanguageSelected
}) => {

  const { languageCode } = useLanguageState();
  const ariaLabel = chooseLanguages[languageCode]?.text || "Select site language";

  const menuProps: SelectMenuProps = {
    menuItems: languages
      .filter(({ code }) => !omitLanguageCodes.find((o) => o === code))
      .map((c) => ({ id: c.code, content: c.name })),
    onMenuItemSelected: onLanguageSelected,
    selectedItemId: languageCode,
    minwidth: "11rem",
    initialPlaceHolder: "Select language",
    icon: "icon-globe",
    menuAriaLabel: ariaLabel
  };

  return <StyledSelectMenu {...menuProps} menuAriaLabel={ariaLabel} />;
};

type WithSelectionHandlerAndBackendProps = Omit<
  LanguagesMenuProps,
  "languages" | "onLanguageSelected" | "ariaLabel"
>;

const WithSelectionHandlerAndBackend: FC<WithSelectionHandlerAndBackendProps> = (
  props: WithSelectionHandlerAndBackendProps
) => {
  return (
    <LanguagesWithBackend
      render={({ languages }) => (
      <LanguageSelectionHandler
          languages={languages}
          render={({ onLanguageSelected }) => (
            <LanguagesMenu
              languages={languages}
              onLanguageSelected={onLanguageSelected}
              {...props}
            />
          )} />
        )}
    />
  );
};

export default WithSelectionHandlerAndBackend;
