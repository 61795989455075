import { languages, makeTemplate, TranslationKey } from "./languages";

type TranslationType = { text?: string };
const home: Record<TranslationKey, TranslationType> = {
  "en-GB": { text: "Home" },
  "da-DK": { text: "Hjem" },
  "fi-FI": { text: "Koti" },
  "is-IS": { text: "Heim" },
  "no-NO": { text: "Hjem" },
  "sv-SE": { text: "Hem" },
};

const breadCrumb: Record<TranslationKey, TranslationType> = {
    "en-GB": { text: "Breadcrumb trail" },
    "da-DK": { text: "Brødkrummesti" },
    "fi-FI": { text: "Murupolku" },
    "is-IS": { text: "Brauðmolaslóð" },
    "no-NO": { text: "Brødsmulesti" },
    "sv-SE": { text: "Brödsmulestig" },
  };
  
const getTranslation = (translations: Record<TranslationKey, TranslationType>, languageCode: string): TranslationType =>
  translations[languageCode] ?? translations["en-GB"];

export const getHome = (languageCode: string) => {
  let { text } = getTranslation(home, languageCode);
  return text ?? "Home";
};

export const getBreadCrumb = (languageCode: string) => {
  let { text } = getTranslation(breadCrumb, languageCode);
  return text ?? "Breadcrumb trail";
};
